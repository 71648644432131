import React, { useEffect, useContext } from "react";
import Seo from "../components/Seo/Seo";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import { Box, Button, Title, Text } from "../components/Core";
import { device } from "../utils";
import { getAssociativeArray, getProperty } from "../utils/helperFn";
import { Container, Image, Row, Col } from "react-bootstrap";
import yflogo from "../assets/image/svg/yf-logo.svg";
import GlobalContext from "../context/GlobalContext";
import { navigate, graphql, useStaticQuery } from "gatsby"
import netomniaCircleLogo from "./../../src/assets/image/png/netomnia-circle-logo.png";

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BoxInner = styled(Box)`
  min-height: 100vh;
`;

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  box-shadow: ${({ theme }) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.light};
  width: 100%;
  display: block;
  text-align: center;
`;

const SuccessPage = (props) => {

    const gContext = useContext(GlobalContext)

    const data = useStaticQuery(graphql`
        query getSuccessRegisterPageData {
            allStrapiOrderJourney {
                nodes {
                    sales_status
                    title
                    subtitle_1
                }
            }
        }`
    );

    const staticText = getAssociativeArray(data.allStrapiOrderJourney.nodes, 'sales_status');

    useEffect(() => {
        gContext.hideFullScreenLoader();
    }, [])

    const renderRegistrationTitle = (pageText) => {
        return <Title className="mt-3 mb-4 text-center">{getProperty(pageText, 'title')}</Title>;
    }

    const renderRegistrationSubtitle = (pageText) => {
        return <Row>
            <Col sm={12} md={9} className="mx-auto mb-4">
                <Text variant="card" className="text-center mb-4 mx-auto">{getProperty(pageText, 'subtitle_1')}</Text>
                {getProperty(pageText, 'subtitle_2') && <Text variant="card" className="text-center mb-4 mx-auto">{getProperty(pageText, 'subtitle_2')}</Text>}
            </Col>
        </Row>
    }

    // Define text status.
    const textStatus = gContext.goGetRegistrationData()?.SearchPremiseResults ? getProperty(gContext.goGetRegistrationData()?.SearchPremiseResults.properties, 'NetomniaSalesStatus') : undefined

    // Define texts for page with specific sales status or null/undefined.
    const pageText = staticText[textStatus ? textStatus : 'default'];

    return (
        <div>
            <Seo title='Netomnia | Success' description='Netomnia | Success' />
            <PageWrapper footerDark>
                <BoxStyled bg="#f7f8fa">
                    <BoxInner className="d-flex align-items-center">
                        <Container>
                            <Box>
                                {
                                    props.location.state?.pageData ?
                                        props.location.state?.pageData?.continueOrder ?
                                            <FormStyled>
                                                {renderRegistrationTitle(pageText)}
                                                {renderRegistrationSubtitle(pageText)}
                                                <h5 className="mt-0" style={{ color: '#000' }}>
                                                    Choose the available provider
                                                </h5>
                                                <Text className="text-center mb-4 mx-auto">
                                                    You will be transferred to the selected provider to select your internet package.
                                                </Text>
                                                <br></br>
                                                <div>
                                                    <a href={process.env.GATSBY_YOUFIBRE_URL + `/checkout-landing?lead=` + props.location.state?.pageData.leadID + `&sprn=` + props.location.state?.pageData.sprn}>
                                                        <Image alt="" src={yflogo} width={160} style={{ display: 'block', margin: 'auto' }} />
                                                        <Button className="mt-3">
                                                            YouFibre Website
                                                        </Button>
                                                    </a>
                                                </div>
                                            </FormStyled>
                                            :
                                            props.location.state?.pageData?.directRedirectToYf === true ?
                                            <FormStyled>
                                                <img src={netomniaCircleLogo} />
                                                {renderRegistrationTitle(pageText)}
                                                {renderRegistrationSubtitle(pageText)}
                                                <div>
                                                    <a href={process.env.GATSBY_YOUFIBRE_URL}>
                                                        <Image alt="" src={yflogo} width={160} style={{ display: 'block', margin: 'auto' }}/>
                                                        <Button className="mt-3">
                                                            YouFibre Website
                                                        </Button>
                                                    </a>
                                                </div>
                                            </FormStyled>
                                            :
                                            <FormStyled>
                                                <img src={netomniaCircleLogo} />
                                                {renderRegistrationTitle(pageText)}
                                                {renderRegistrationSubtitle(pageText)}
                                                <Button
                                                    className="mt-50 light-bg"
                                                    onClick={() => {
                                                        navigate('/');
                                                    }}
                                                >Return to homepage</Button>
                                            </FormStyled>
                                        :
                                        <FormStyled>
                                            <img src={netomniaCircleLogo} />
                                            {renderRegistrationTitle(pageText)}
                                            {renderRegistrationSubtitle(pageText)}
                                            <Button
                                                className="mt-50 light-bg"
                                                onClick={() => {
                                                    navigate('/');
                                                }}
                                            >Return to homepage</Button>
                                        </FormStyled>
                                }
                            </Box>
                        </Container>
                    </BoxInner>
                </BoxStyled>
            </PageWrapper>
        </div>
    )
}

export default SuccessPage